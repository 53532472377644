import React, { Component } from "react";

class Counter extends Component {
  render() {
    return (
      <div className="row-counter parallax parallax-4 parallax-overlay">
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12">
              <div
                className="themesflat-spacer clearfix"
                data-desktop={92}
                data-mobile={60}
                data-smobile={60}
              />
            </div>
          </div>
          <div className="themesflat-row gutter-30 separator light clearfix counterStyleDiv">
            <div className="col span_1_of_3">
              <div
                className="themesflat-content-box clearfix"
                data-margin="0 0 0 0"
                data-mobilemargin="0 0 0 0"
              >
                <div className="themesflat-counter style-1 align-center clearfix">
                  <div className="counter-item">
                    <div className="inner">
                      <div className="text-wrap">
                        <div className="number-wrap">
                          <span
                            className="number"
                            data-speed={2000}
                            data-to={50}
                            data-inviewport="yes"
                          >
                            50
                          </span>
                          <span className="suffix">+</span>
                        </div>
                        <h3 className="heading margin-right-18">
                          LAKHS SQ.FT COVERED
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={35}
                data-smobile={35}
              />
            </div>
            <div className="col span_1_of_3">
              <div
                className="themesflat-content-box clearfix"
                data-margin="0 0 0 0"
                data-mobilemargin="0 0 0 0"
              >
                <div className="themesflat-counter style-1 align-center clearfix">
                  <div className="counter-item">
                    <div className="inner">
                      <div className="text-wrap">
                        <div className="number-wrap">
                          <span
                            className="number"
                            data-speed={2000}
                            data-to={100}
                            data-inviewport="yes"
                          >
                            100
                          </span>
                          <span className="suffix">+</span>
                        </div>
                        <h3 className="heading margin-right-6">
                          PROJECT COMPLETED
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={35}
                data-smobile={35}
              />
            </div>
            <div className="col span_1_of_3">
              <div
                className="themesflat-content-box clearfix"
                data-margin="0 0 0 0"
                data-mobilemargin="0 0 0 0"
              >
                <div className="themesflat-counter style-1 align-center clearfix">
                  <div className="counter-item">
                    <div className="inner">
                      <div className="text-wrap">
                        <div className="number-wrap">
                          <span
                            className="number"
                            data-speed={2000}
                            data-to={100}
                            data-inviewport="yes"
                          >
                            100
                          </span>
                          <span className="suffix">+</span>
                        </div>
                        <h3 className="heading margin-right-8">
                          ACTIVE CLIENTS
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={0}
                data-smobile={35}
              />
            </div>
            {/* <div className="col span_1_of_3">
              <div
                className="themesflat-content-box clearfix"
                data-margin="0 0 0 0"
                data-mobilemargin="0 0 0 0"
              >
                <div className="themesflat-counter style-1 align-center clearfix">
                  <div className="counter-item">
                    <div className="inner">
                      <div className="text-wrap">
                        <div className="number-wrap">
                          <span
                            className="number"
                            data-speed={2000}
                            data-to={2984}
                            data-inviewport="yes"
                          >
                            2984
                          </span>
                          <span className="suffix">+</span>
                        </div>
                        <h3 className="heading margin-right-10">
                          WORKERS EMPLOYED
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row clearfix">
            <div className="col-md-12">
              <div
                className="themesflat-spacer clearfix"
                data-desktop={72}
                data-mobile={60}
                data-smobile={60}
              />
            </div>
          </div>
        </div>
        <div className="bg-parallax-overlay overlay-black style2" />
      </div>
    );
  }
}

export default Counter;
